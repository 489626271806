import firebase from 'firebase/auth';

export default {
  namespaced: true,
  state: () => ({
    user: {
      loggedIn: false,
      data: null,
    },
  }),
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value;
    },
    async SET_USER(state, data) {
      state.user.data = data;
      sessionStorage.setItem('token', await firebase.auth().currentUser.getIdToken(true));
    },
  },
  actions: {
    async fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null);
      if (user) {
        commit('SET_USER', {
          displayName: user.displayName,
          email: user.email,
        });
      } else {
        commit('SET_USER', null);
      }
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
};
