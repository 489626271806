import Vue from 'vue';
import Vuetify, { VBtn, VRow } from 'vuetify/lib';
import Ripple from 'vuetify/lib/directives';
import colors from 'vuetify/lib/util/colors';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const opts = {
  theme: {
    themes: {
      light: {
        primary: colors.blueGrey,
        secondary: colors.grey.darken1,
        accent: colors.shades.black,
        error: colors.red.accent3,
        background: colors.blue.lighten5,
      },
      dark: {
        primary: colors.blueGrey.darken1,
      },
    },
    dark: JSON.parse(localStorage.getItem('isDarkTheme')),
  },
  components: {
    VBtn,
    VRow,
  },
  directives: {
    Ripple,
  },
};

export default new Vuetify(opts);
