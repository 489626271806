import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import SupportUs from '@/views/SupportUs.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/support-us',
    name: 'SupportUs',
    component: SupportUs,
  },
  {
    path: '/submarines/calculator',
    name: 'SubmarineCalculator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineCalculator.vue'),
  },
  {
    path: '/submarines/exp-calculator',
    name: 'SubmarineExpCalculator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineExpCalculator.vue'),
  },
  {
    path: '/submarines/sectors',
    name: 'SubmarineMaps',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineMaps.vue'),
  },
  {
    path: '/submarines/ranks',
    name: 'SubmarineRanks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "submarines" */ '../views/SubmarineRanks.vue'),
  },
  {
    path: '/airships/calculator',
    name: 'AirshipCalculator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipCalculator.vue'),
  },
  {
    path: '/airships/exp-calculator',
    name: 'AirshipExpCalculator',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipExpCalculator.vue'),
  },
  {
    path: '/airships/sectors',
    name: 'AirshipSectors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipSectors.vue'),
  },
  {
    path: '/airships/ranks',
    name: 'AirshipRanks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "airships" */ '../views/AirshipRanks.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
