<template>
  <div class="home">
    <p>Welcome~</p>
    <br>
    <p>
      If you have any questions, leave a message on the <b>FFXIV Submarine Builders</b> discord!
    </p>
    <p>
      Also big thanks to people contributing to provide us data!
    </p>
  </div>
</template>

<script>

export default {
  name: 'Home',
};
</script>
