<template>
  <div style="padding: 10px;">
    <h3>Support us</h3>
    <p>
      You can support us through
      <br>
      <a href="https://www.patreon.com/bePatron?u=53799045" target="_blank">
        <img src="/img/patreon.png" alt="Become a Patron!" height='36'>
      </a>
      or
      <a href='https://ko-fi.com/H2H149C7Z' target='_blank'>
        <img height='36' style='border:0px;height:36px;' src='https://cdn.ko-fi.com/cdn/kofi2.png?v=2' border='0' alt='Buy Me a Coffee at ko-fi.com' />
      </a>
    </p>
  </div>
</template>

<script>

export default {
  name: 'SupportUs',
};
</script>
