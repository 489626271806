export default class AirshipCalculatorService {
  static getTotalTime(time, speed) {
    const days = ((time / speed) / 1440) + 0.5;
    const hours = ((time / speed) / 60 + 12) % 24;
    const minutes = (time / speed) % 60;
    return {
      days: Math.trunc(days),
      hours: Math.trunc(hours),
      minutes: Math.trunc(minutes),
    };
  }

  static calculateTrip(builds, time) {
    const buildsWithTime = [];
    for (let i = 0; i < builds.length; i += 1) {
      const build = { ...builds[i] };
      build.time = time ? this.getTotalTime(time, build.stats.speed) : null;
      buildsWithTime.push(build);
    }
    return buildsWithTime;
  }

  static getStatFilter(statName, values) {
    if (!values) {
      return [];
    }
    return Object.entries(values).filter(([, value]) => value).map(([key, value]) => {
      const name = ['min', 'exact', 'max'].includes(key) ? `${statName}_${key}` : statName;
      return {
        [name]: value,
      };
    });
  }

  static calculateBreakpoints(routes) {
    if (!routes || routes.length === 0
      || routes[0].sectors.filter((s) => s.breakpoints).length === 0) {
      return {
        surveillance: null,
        retrieval: null,
        favor: null,
      };
    }

    const route = routes[0];

    const surveillanceList = route.sectors.map((s) => s.breakpoints.surveillance);
    const retrievalList = route.sectors.map((s) => s.breakpoints.retrieval);
    const favorList = route.sectors.map((s) => s.breakpoints.favor);

    const midSurvey = Math.max(...surveillanceList.map((bp) => bp.mid));
    const highSurvey = Math.max(...surveillanceList.map((bp) => bp.high));

    const normRet = Math.max(...retrievalList.map((bp) => bp.norm));
    const optimRet = Math.max(...retrievalList.map((bp) => bp.optim));

    const favor = favorList.reduce((a, b) => Math.max(a, b));

    return {
      surveillance: { mid: midSurvey, high: highSurvey },
      retrieval: { norm: normRet, optim: optimRet },
      favor,
    };
  }
}
