<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />
        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              text
            >
              <span class="mr-2">Submarines</span>
            </v-btn>
          </template>
          <v-list dense>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/submarines/calculator' }" text>
                  <span class="mr-2">Builds Calculator</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/submarines/exp-calculator' }" text>
                  <span class="mr-2">XP Calculator</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/submarines/sectors' }" text>
                  <span class="mr-2">Sectors</span>
                </v-btn>
              </v-list-item-title>
              </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/submarines/ranks' }" text>
                  <span class="mr-2">Ranks</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu
          open-on-hover
          bottom
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              dark
              v-bind="attrs"
              v-on="on"
              text
            >
              <span class="mr-2">Airships</span>
            </v-btn>
          </template>

          <v-list dense>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/airships/calculator' }" text>
                  <span class="mr-2">Builds Calculator</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/airships/exp-calculator' }" text>
                  <span class="mr-2">XP Calculator</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/airships/sectors' }" text>
                  <span class="mr-2">Sectors</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <v-btn
                  style="width: 100%"
                  tag="router-link" :to="{ path: '/airships/ranks' }" text>
                  <span class="mr-2">Ranks</span>
                </v-btn>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-btn tag="router-link" :to="{ path: '/support-us' }" text>
          <span class="mr-2">Support us</span>
        </v-btn>
        <v-btn tag="router-link" href="https://discord.gg/PUkcQH8nFh" text>
          <span class="mr-2">Discord</span>
        </v-btn>
      </div>

      <v-spacer></v-spacer>
      <v-btn @click="toogleDarkMode" text>
        <span class="mr-2">Dark/Light Mode</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <RouterView/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  components: {
  },

  data: () => ({
    //
  }),

  mounted: () => {
  },

  methods: {
    toogleDarkMode() {
      const isDarkTheme = !this.$vuetify.theme.dark;
      localStorage.setItem('isDarkTheme', isDarkTheme);
      this.$vuetify.theme.dark = isDarkTheme;
    },
  },
};
</script>
