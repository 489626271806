import axios from 'axios';
import axiosRetry from 'axios-retry';

const apiUrl = process.env.VUE_APP_API_URL || 'http://localhost:8000';

export default (() => {
  const http = axios.create({
    baseURL: apiUrl,
  });
  http.interceptors.request.use((config) => {
    const newConfig = { ...config };
    const token = sessionStorage.getItem('token');
    if (token) {
      newConfig.headers.Authorization = `Bearer ${token}`;
    }

    return newConfig;
  }, (error) => Promise.reject(error));

  axiosRetry(http, {
    retries: 3,
    retryDelay: () => 5000,
    shouldResetTimeout: true,
    setTimeout: 15000,
    retryCondition: () => true, // retry no matter what
  });

  return http;
})();
