import SubmarineCalculatorService from '../../services/submarine-calculator.service';

const initialStats = () => ({
  rank: {
    min: null,
    max: null,
    exact: 120,
  },
  surveillance: {
    min: null,
    max: null,
    exact: null,
  },
  retrieval: {
    min: null,
    max: null,
    exact: null,
  },
  speed: {
    min: null,
    max: null,
    exact: null,
  },
  range: {
    min: null,
    max: null,
    exact: null,
  },
  favor: {
    min: null,
    max: null,
    exact: null,
  },
});

const submarinesCalculator = {
  namespaced: true,
  state: () => ({
    selectedRoute: null,
    routes: [],
    sortingPriority: [],
    sorting: {
      sortBy: [],
      sortDesc: [],
      sort: [],
    },
    noLimitOnResults: false,
    uriParams: null,
    builds: [],
    isLoading: false,
    breakpoints: {
      surveillance: null,
      retrieval: null,
      range: null,
      favor: null,
    },
    breakpointsToApply: {
      surveillance: null,
      retrieval: null,
      range: null,
      favor: null,
    },
    stats: initialStats(),
    selectedParts: [],
  }),
  mutations: {
    RESET_STATS(state) {
      state.stats = initialStats();
    },
    SET_SELECTED_ROUTE(state, value) {
      state.selectedRoute = value;
    },
    SET_ROUTES(state, value) {
      state.routes = value;
      state.breakpoints = SubmarineCalculatorService.calculateBreakpoints(value);
    },
    SET_SORTING_PRIORITY(state, value) {
      state.sortingPriority = value;
    },
    SET_SORTING_SORTBY(state, value) {
      state.sorting.sortBy = value;
    },
    SET_SORTING_SORTDESC(state, value) {
      state.sorting.sortDesc = value;
    },
    SET_SORTING_SORT(state, value) {
      state.sorting.sort = value;
    },
    SET_BUILDS(state, value) {
      if (value) {
        value = value.map((v, i) => ({
          _id: i,
          ...v,
        }));
      }
      state.builds = value;
    },
    SET_RANK_STAT(state, value) {
      state.stats.rank = value;
    },
    SET_SURVEILLANCE_STAT(state, value) {
      state.stats.surveillance = value;
    },
    SET_RETRIEVAL_STAT(state, value) {
      state.stats.retrieval = value;
    },
    SET_SPEED_STAT(state, value) {
      state.stats.speed = value;
    },
    SET_RANGE_STAT(state, value) {
      state.stats.range = value;
    },
    SET_FAVOR_STAT(state, value) {
      state.stats.favor = value;
    },
    SET_SELECTED_PARTS(state, value) {
      state.selectedParts = value;
    },
    SET_BREAKPOINTS_TO_APPLY(state, value) {
      state.breakpointsToApply = value;
    },
    SET_URI_PARAMS(state, value) {
      state.uriParams = value;
    },
    SET_NO_LIMIT_ON_RESULTS(state, value) {
      state.noLimitOnResults = value;
    },
    START_LOADING(state) {
      state.isLoading = true;
    },
    STOP_LOADING(state) {
      state.isLoading = false;
    },
  },
  actions: {
    updateSorting({ commit, state }, payload) {
      const sortBy = payload.type === 'sortBy' ? payload.data : state.sorting.sortBy;
      const sortDesc = payload.type === 'sortDesc' ? payload.data : state.sorting.sortDesc;
      const sort = [];

      for (const [key, value] of Object.entries(sortBy)) {
        sort.push({
          field: value,
          order: sortDesc[key] ? 'desc' : 'asc',
        });
      }
      commit('SET_SORTING_SORT', sort);
    },
    fetchBuilds({ commit, state }) {
      const params = {};

      SubmarineCalculatorService.getStatFilter('rank', state.stats.rank).forEach((row) => {
        const key = Object.keys(row)[0];
        params[key] = row[key];
      });

      SubmarineCalculatorService.getStatFilter('surveillance', state.stats.surveillance).forEach((row) => {
        const key = Object.keys(row)[0];
        params[key] = row[key];
      });
      if (state.breakpointsToApply.surveillance) {
        params.surveillance_min = state.breakpointsToApply.surveillance;
      }

      SubmarineCalculatorService.getStatFilter('retrieval', state.stats.retrieval).forEach((row) => {
        const key = Object.keys(row)[0];
        params[key] = row[key];
      });
      if (state.breakpointsToApply.retrieval) {
        params.retrieval_min = state.breakpointsToApply.retrieval;
      }

      SubmarineCalculatorService.getStatFilter('speed', state.stats.speed).forEach((row) => {
        const key = Object.keys(row)[0];
        params[key] = row[key];
      });

      SubmarineCalculatorService.getStatFilter('range', state.stats.range).forEach((row) => {
        const key = Object.keys(row)[0];
        params[key] = row[key];
      });
      if (state.breakpointsToApply.range) {
        params.range_min = state.breakpointsToApply.range;
      }

      SubmarineCalculatorService.getStatFilter('favor', state.stats.favor).forEach((row) => {
        const key = Object.keys(row)[0];
        params[key] = row[key];
      });
      if (state.breakpointsToApply.favor) {
        params.favor_min = state.breakpointsToApply.favor;
      }

      if (state.noLimitOnResults) {
        params.noLimitOnResults = 1;
      }

      if (state.sorting.sort instanceof Array && state.sorting.sort.length > 0) {
        const sort = [];
        for (const item of state.sorting.sort) {
          sort.push(`${item.field},${item.order}`);
        }
        params.sort = sort.join(',');
      }

      const hull = [];
      const stern = [];
      const bow = [];
      const bridge = [];
      state.selectedParts.forEach((row) => {
        if (row.hull.checked) {
          hull.push(row.hull.id);
        }
        if (row.stern.checked) {
          stern.push(row.stern.id);
        }
        if (row.bow.checked) {
          bow.push(row.bow.id);
        }
        if (row.bridge.checked) {
          bridge.push(row.bridge.id);
        }
      });

      if (hull.length > 0) {
        params.slot0 = hull.join(',');
      }
      if (stern.length > 0) {
        params.slot1 = stern.join(',');
      }
      if (bow.length > 0) {
        params.slot2 = bow.join(',');
      }
      if (bridge.length > 0) {
        params.slot3 = bridge.join(',');
      }

      commit('SET_URI_PARAMS', params);

      commit('START_LOADING');
      return this._vm.$http.get('/submarine/builds-calculator', { params: state.uriParams })
        .then((res) => {
          if (state.selectedRoute && state.selectedRoute.time) {
            commit('SET_BUILDS', SubmarineCalculatorService.calculateTrip(res.data?.results, state.selectedRoute.time));
          } else {
            commit('SET_BUILDS', res.data?.results);
          }
        }).finally(() => {
          commit('STOP_LOADING');
        });
    },
  },
  getters: {
    selectedRoute(state) {
      return state.selectedRoute;
    },
    routes(state) {
      return state.routes;
    },
    sortingPriority(state) {
      return state.sortingPriority;
    },
    builds(state) {
      return state.builds;
    },
    isLoading(state) {
      return state.isLoading;
    },
    breakpoints(state) {
      return state.breakpoints;
    },
    breakpointsToApply(state) {
      return state.breakpointsToApply;
    },
  },
};

export default submarinesCalculator;
