import Vue from 'vue';
import Vuex, { createLogger } from 'vuex';
import auth from './modules/auth';
import airshipsCalculator from './modules/airships-calculator';
import submarinesCalculator from './modules/submarines-calculator';

Vue.use(Vuex);
const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    airshipsCalculator,
    submarinesCalculator,
  },
  plugins: debug ? [createLogger()] : [],
});
